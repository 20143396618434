<template>
  <el-container>
    <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
      :buttonConfig="buttonConfig" @handleButtonClick="callMethod" @editRow="handleShowDialog">
    </grid-table>
    <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></edit-dialog>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
export default {
  name: "pageList",
  data() {
    return {
      routeParam: {},
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      editFormConfig: {},
    };
  },
  mounted() {
    this.routeParam = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
    //this.tableConfig.searchForm.items
    this.$nextTick(async () => {
      if (this.tableConfig.searchForm.url) {
        const { data } = await submit(this.tableConfig.searchForm.url);
        const { items } = this.getFormRules(
          this.tableConfig.searchForm.items,
          data.InitData
        );
        this.$set(this.tableConfig.searchForm, "items", items);
      }
    });
  },
  activated() {
    var param = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
    if (Object.keys(param).length > 0 && this.routeParam != param) {
      this.routeParam = param;
    }
  },
  watch: {
    routeParam: {
      deep: true,
      handler() {
        this.handleQuery();
      },
    },
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.fetchData(false, null, this.routeParam);
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleShowDialog(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (button.isNeedSelectRow && !button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(
          button.label,
          _this.getTableParam(
            _this.selectRows,
            button.isNeedRowKey,
            button.isMultiSelectRow,
            _this.tableConfig.rowKey,
            button.paramJson
          )
        );
      });
    },
    async handleExportExcel(_this, button) {
      const { data } = await exportFile(button.url);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data.FileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
};
</script>
